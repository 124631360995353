<template>

  <span>

    <div v-if='!isMobile()' :class="'row fI-slow deviceListBody ' + currentStatus()">
      <div :class="'itemRowElement col-sm-1 deviceListXSCol'" style="margin-left: -25px;margin-right: 5px;padding: 0 0px 0 15px;">
        <!--input
          type='checkbox'
          name='selectDevice'
          style="float: left;width: 12px;margin: 2px 0 0 0;"
        /-->
        <span style="float:right; margin-right: 5px;">
          <i v-if="itemState !== 'Offline'" :title="$gettext('Pool status') + ': ' + getPoolstatusText(miner.pool_status)" :class="'fas fas-xsBtn' + getPoolstatus(miner.pool_status)"></i>
          <i v-else :title="$gettext('Device is offline.')" :class="'fas fas-xsBtn fa-times-circle red'"></i>
        </span>
      </div>
      <!--
      <div v-if="workerMode == 65536 || workerMode == 1114112" :class="'itemRowElement col-sm-2'" style="margin-right: 0px;text-align:center;">
        <div style="width: 90%;display: inline-flex;margin-left: 0px;"><i class="fas fa-edit fas-small fas-left color01 inlineEditIcon" style="margin-top: 4px; margin-right: -3px;" :title="$gettext('Click to edit Workername')" @click="$refs.editWorkerName.openModal();"></i><div class="truncateRight">{{ miner.name ? miner.name : (miner.worker_name ? miner.worker_name : 'n/a') }}</div></div>
      </div>
      <div v-else :class="'itemRowElement col-sm-2'" style="margin-right: 0px;text-align:center;">
        <div style="width: 100%;"><div class="truncateRight" style="color: rgb(140 140 140);">{{ (miner.worker_name ? miner.worker_name : 'n/a') }}</div></div>
      </div>
      -->
      <div :class="'itemRowElement col-sm-2 truncateRight'" style="margin-left: -15px; margin-right: 10px; padding: 0;">{{ (miner.ip_address ? miner.ip_address : 'n/a') }}</div>
      <div :class="'itemRowElement col-sm-2 '" style="margin-left: 0px; margin-right: 2px;"><b>{{ minerModel ? minerModel : 'n/a' }}</b></div>
      <div :class="'itemRowElement col-sm-1 color03'" style="margin-right: 5px; padding: 0px;"><b>{{ miner.hashrate_ghs_5m ? format1MinHashrate(miner.hashrate_ghs_5m) + ' TH/s' : 'n/a' }}</b></div>
      <div :class="'itemRowElement col-sm-1 grey'" style="margin-left: 5px; margin-right: 10px; padding: 0px;"><b>{{ miner.pool_hashrate_1h ? format1MinHashrate(miner.pool_hashrate_1h) + ' TH/s' : 'n/a' }}</b></div>
      <div :class="'itemRowElement col-sm-1 ' + chipcountIndication()">{{ miner.active_asic_chips ? miner.active_asic_chips : 'n/a' }}</div>
      <div :class="'itemRowElement col-sm-1 ' + temperatureIndication()" style="margin-left: 0px; margin-right: -6px;" :title="$gettext('The average temperature of all boards combined.')">{{ miner.temperatures ? miner.temperatures.toFixed(0) + '°' : 'n/a' }}</div>
      <div :class="'itemRowElement col-sm-1 ' + fanspeedIndication()" style="margin-right: -5px; margin-left:3px;" :title="$gettext('The average fan speed of all fans combined.')">{{ miner.fanspeeds ? miner.fanspeeds + '%' : 'n/a' }}</div>
      <div :class="'itemRowElement col-sm-1'" :title="$gettext('The current approximate power consumption of the device.')">{{ miner.power_consumption ? miner.power_consumption + 'W' : 'n/a' }}</div>
      <div class="col-sm-1 fullWidthModal" style="margin:auto;">
        <div class="justify-content-between" style="display: inline-flex; margin: auto;">
          <StatsModal :miner="miner" :fanspeedIndication="fanspeedIndication()" :temperatureIndication="temperatureIndication()" :chipcountIndication="chipcountIndication()" :statsBtnText="$gettext('')" :statsBtn="'secondary'" :status="currentStatus()"/>
          <!--span>
            <button type="button" class="btn btn-primary xsBtn xsBtnSquareSmall" :title="$gettext('Reset this miners configuration')" @click="$refs.resetMinerModal.openModal()"><i class="fas fas-xsBtn fa-recycle"></i></button>
          </span-->
          <span>
            <button type="button" class="btn btn-primary xsBtn xsBtnSquareSmall" :title="$gettext('Remove this miner')" @click="$refs.deleteMinerModal.openModal()"><i class="fas fas-xsBtn fa-trash"></i></button>
          </span>
        </div>
      </div>

    </div>

    <!-- MOBILE -->

    <div v-else :class="'row fI-slow deviceListBody ' + currentStatus()">

      <div class="itemRowElement col-1" style="margin-left: 0px; margin-right: 0px;"><b>{{ minerModel }}</b></div>
      <div class="itemRowElement col-4 color03" style="margin-left: -5px; margin-right: -5px;"><b>{{ miner.hashrate_ghs_1m ? format1MinHashrate(miner.hashrate_ghs_1m) + 'TH/s' : 'n/a' }}</b></div>
      <div class="itemRowElement col-4" style="margin-left: 0px; margin-right: 5px; padding: 0;text-align:center;" :title="miner.worker_name"><div style="display: inline-flex;width: 100%;"><i class="fas fa-cogs fas-small fas-left color01 inlineEditIcon" style="margin-top:4px; display:none;" :title="$gettext('Click to edit Workername')" @click="$refs.editWorkerName.openModal();"></i><div class="truncateRight">{{ (miner ? miner.worker_name : 'n/a') }}</div></div></div>
      <div class="itemRowElement col-3 fullWidthModal" style="padding: 0;">
        <div class="justify-content-between" style="display: inline-flex;">
          <StatsModal
            :miner="miner"
            :fanspeedIndication="fanspeedIndication"
            :temperatureIndication="temperatureIndication"
            :chipcountIndication="chipcountIndication"
          />
          <span>
            <button type="button" style="width: 30px!important;" class="btn btn-primary xsBtn xsBtnSquareSmall" :title="$gettext('Remove this miner')" @click="$refs.deleteMinerModal.openModal()"><i class="fas fas-xsBtn fa-trash"></i></button>
          </span>
        </div>
      </div>

    </div>

    <!-- MODAL : SAVE NOTES -->

    <Modal ref="addNoteModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-file fas-left"></i> <translate>NOTES</translate></h5>
      </template>
      <template v-slot:body>
        <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
          <div><i class="grow fas fa-check fas-small fas-left green" style="font-size: 4em;"></i></div>
          <div class="pulsate" style="margin-top:12px;" v-translate>Notes for device <b>{{ miner.device_id }}</b> updated!</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary' @click="$refs.addNoteModal.closeModal();"><i class="fas fas-xsBtn fa-times fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : EDIT WORKER -->

    <Modal ref="editWorkerName">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-edit fas-left"></i> <translate>EDIT</translate> <small class="color01">_ <translate>WORKER</translate></small></h5>
      </template>
      <template v-slot:body>
        <FormulateInput
            :label="$gettext('NEW WORKER NAME')"
            :tooltip="$gettext('Enter the desired workername for this miner. (Min chars 3, max chars 16 and alphanumeric.)')"
            type='text'
            v-model='workername'
            :placeholder="$gettext('Enter new worker name...')"
            name='deviceset_id'
        />
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary trans' @click="$refs.editWorkerName.closeModal();"><i class="fas fas-xsBtn fa-times fas-left"></i><translate>CANCEL</translate></button>
          <button v-if='workername && workername.length > 2 && workername.length < 16' class='btn btn-success trans' @click="editWorkerName();$refs.configUpdateSuccess.openModal();$refs.editWorkerName.closeModal();"><i class="fas fas-xsBtn fa-save fas-left"></i><translate>SAVE CHANGES</translate></button>
          <button v-else class='btn btn-primary trans' style="opacity: 0.2; cursor: not-allowed;" :title="$gettext('Enter a valid worker name...')"><i class="fas fas-xsBtn fa-save fas-left"></i><translate>SAVE CHANGES</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : DELETE DEVICE -->

    <Modal ref="deleteMinerModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-times fas-left"></i> <translate>DELETE DEVICE</translate></h5>
      </template>
      <template v-slot:body>
        <div><i class="grow fas fa-times fas-small fas-left deepred" style="font-size: 4em; color: #d9222c;"></i></div>
        <div class="pulsate deepred" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px; color: #d9222c;"> <translate>DELETE SELECTED DEVICE?</translate> </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button type="button" class='btn btn-primary' style="margin-top:-1px; margin-right: 5px;" @click="$refs.deleteMinerModal.closeModal();"><i class="fas fas-xsBtn fa-times fas-left"></i><translate>NO</translate></button>
          <button type="button" class='btn btn-danger' @click="deleteMiner();$refs.deleteMinerModal.closeModal();"><i class="fas fas-xsBtn fa-check fas-left"></i><translate>YES</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : RESET CONFIG -->

    <Modal ref="resetMinerModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-recycle fas-left"></i> <translate>RESET CONFIG</translate></h5>
      </template>
      <template v-slot:body>
        <div><i class="grow fas fa-cogs fas-small fas-left" style="font-size: 4em;"></i></div>
        <div class="pulsate" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>RESET DEVICE CONFIG?</translate> </div>
        <div style="width: 60%; margin: auto; margin-top:10px;"><small><span class="grey" v-translate>This is often very helpful during <b>troubleshooting</b>. The miner should reconnect and update its status within several minutes.</span></small></div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button type="button" class='btn btn-primary' style="margin-top:-1px; margin-right: 5px;" @click="$refs.resetMinerModal.closeModal();"><i class="fas fas-xsBtn fa-times fas-left"></i><translate>NO</translate></button>
          <button type="button" class='btn btn-danger' @click="resetConfig();$refs.resetMinerModal.closeModal();"><i class="fas fas-xsBtn fa-check fas-left"></i><translate>YES</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : SUCCESS -->

    <Modal ref="configUpdateSuccess">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-check-circle fas-left"></i> <translate>INFO</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div><i class="grow fas fa-check fas-small fas-left" style="font-size: 3em; color: #13A454;"></i></div>
            <div class="pulsate" style="letter-spacing: 3px; font-size: 1em; margin-top: 9px; margin-bottom: 10px;color: #13A454;"> <translate>UPDATE SUCCESSFUL</translate> </div>
            <div style="width: 60%; margin: auto;"><small><span class="grey"><translate>During the configuration update process, data (eg. pool status, stats) might be displayed inaccurately for several minutes.</translate></span></small></div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button value='CLOSE' class='btn btn-primary' @click="$refs.configUpdateSuccess.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : ERROR -->

    <Modal ref="configUpdateError">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-exclamation-triangle fas-left"></i> <translate>ERROR</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
              <div><i class="grow fas fa-times fas-small fas-left red" style="font-size: 4em;"></i></div>
              <div class="pulsate red" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>UPDATE ERROR</translate> </div>
              <div style="margin-top:12px; font-size: 1em; width: 60%; margin-left: auto; margin-right:auto;">{{ errorDetail }}</div>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary' @click="$refs.configUpdateError.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

  </span>

</template>

<script>
import Modal from '@/components/widgets/Modal.vue'
import StatsModal from '@/components/views/Detail/Devices/Modals/ProStats'
import { MINER__DELETE, MINER__UPDATE } from '@/store/actions.type'

export default {
  name: 'itemrow',
  components: { Modal, StatsModal },
  props: ['id', 'miner', 'latest_stats', 'status', 'status_msg', 'workerMode', 'workernameDelimiter', 'productMode'],
  data () {
    return {
      timeNow: Math.floor(Date.now() / 1000),
      itemState: null,
      workername: null,
      minerModel: this.getMinerModel()
    }
  },
  computed: {
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    chipcountIndication () {
      const minerModel = this.minerModel
      const chipCount = this.miner.active_asic_chips || 0
      let result = null
      switch (true) {
        case minerModel === 'S19JPro':
          switch (true) {
            case (this.status === 'offline' || this.itemState === 'Offline'):
              result = ''
              break
            case chipCount >= 370:
              result = 'green'
              break
            case (chipCount < 370 && chipCount >= 360):
              result = 'orange'
              break
            case chipCount < 360:
              result = 'darkred'
              break
          }
          break
        case minerModel === 'S17Pro':
          switch (true) {
            case (this.status === 'offline' || this.itemState === 'Offline'):
              result = ''
              break
            case chipCount >= 195:
              result = 'green'
              break
            case (chipCount < 195 && chipCount >= 185):
              result = 'orange'
              break
            case chipCount < 185:
              result = 'darkred'
              break
          }
          break
        case (minerModel === 'S17' || minerModel === 'S17+'):
          switch (true) {
            case (this.status === 'offline' || this.itemState === 'Offline'):
              result = ''
              break
            case chipCount >= 144:
              result = 'green'
              break
            case (chipCount < 144 && chipCount >= 139):
              result = 'orange'
              break
            case chipCount < 138:
              result = 'darkred'
              break
          }
          break
        case (minerModel === 'T17' || minerModel === 'T17+'):
          switch (true) {
            case (this.status === 'offline' || this.itemState === 'Offline'):
              result = ''
              break
            case chipCount >= 90:
              result = 'green'
              break
            case (chipCount < 90 && chipCount >= 86):
              result = 'orange'
              break
            case chipCount < 86:
              result = 'darkred'
              break
          }
          break
        case minerModel === 'S9':
          switch (true) {
            case (this.status === 'offline' || this.itemState === 'Offline'):
              result = ''
              break
            case chipCount >= 189:
              result = 'green'
              break
            case (chipCount < 189 && chipCount >= 180):
              result = 'orange'
              break
            case chipCount < 180:
              result = 'darkred'
              break
          }
          break
      }
      return result
    },
    fanspeedIndication () {
      const fanSpeed = this.miner.fanspeeds || 0
      let result = null
      switch (true) {
        case (this.status === 'offline' || this.itemState === 'Offline'):
          result = ''
          break
        case fanSpeed >= 85:
          result = 'darkred'
          break
        case (fanSpeed < 85 && fanSpeed >= 65):
          result = 'orange'
          break
        case fanSpeed < 65:
          result = 'green'
          break
      }
      return result
    },
    hwerrorIndication () {
      const hwErrors = this.miner.hw_errors || 0
      let result = null
      switch (true) {
        case hwErrors >= 1000000:
          result = 'darkred'
          break
        case hwErrors >= 50000:
          result = 'orange'
          break
        case hwErrors < 50000:
          result = 'green'
          break
      }
      return result
    },
    temperatureIndication () {
      const minerModel = this.minerModel
      const temp = this.miner.temperatures || 0
      let result = null
      switch (true) {
        case (minerModel === 'S19' || minerModel === 'S19Pro' || minerModel === 'S19JPro'):
          switch (true) {
            case (this.status === 'offline' || this.itemState === 'Offline'):
              result = ''
              break
            case temp > 98:
              result = 'darkred'
              break
            case temp >= 80:
              result = 'orange'
              break
            case (temp < 80 && temp >= 70):
              result = 'green'
              break
            case (temp < 70 && temp >= 60):
              result = 'orange'
              break
            case temp < 60:
              result = 'darkred'
              break
          }
          break
        case (minerModel === 'S17' || minerModel === 'S17+' || minerModel === 'S17Pro' || minerModel === 'S17e' || minerModel === 'T17' || minerModel === 'T17+' || minerModel === 'T17e'):
          switch (true) {
            case (this.status === 'offline' || this.itemState === 'Offline'):
              result = ''
              break
            case temp > 98:
              result = 'darkred'
              break
            case temp >= 80:
              result = 'orange'
              break
            case (temp < 80 && temp >= 70):
              result = 'green'
              break
            case (temp < 70 && temp >= 60):
              result = 'orange'
              break
            case temp < 60:
              result = 'darkred'
              break
          }
          break
        case minerModel === 'S9':
          switch (true) {
            case (this.status === 'offline' || this.itemState === 'Offline'):
              result = ''
              break
            case temp > 97:
              result = 'darkred'
              break
            case temp >= 92:
              result = 'orange'
              break
            case (temp < 92 && temp >= 85):
              result = 'green'
              break
            case (temp < 85 && temp >= 65):
              result = 'orange'
              break
            case temp < 65:
              result = 'darkred'
              break
          }
          break
      }
      return result
    },
    getPoolstatus (data) {
      const poolStatus = this.miner.pool_status || 0
      let statusIcon = null
      switch (true) {
        case poolStatus === 0:
          statusIcon = ' fa-question-circle yellow'
          break
        case poolStatus === 1:
          statusIcon = ' fa-minus-circle yellow'
          break
        case poolStatus === 2:
          statusIcon = ' fa-chevron-circle-left red'
          break
        case poolStatus === 3:
          statusIcon = ' fa-times-circle red'
          break
        case poolStatus === 4:
          statusIcon = ' fa-check-circle green'
          break
        case poolStatus === 5:
          statusIcon = ' fa-question-circle red'
          break
      }
      return statusIcon
    },
    getPoolstatusText (data) {
      const poolStatus = this.miner.pool_status || 0
      let statusText = null
      switch (true) {
        case poolStatus === 0:
          statusText = 'Unspecified'
          break
        case poolStatus === 1:
          statusText = 'Disabled'
          break
        case poolStatus === 2:
          statusText = 'Rejecting'
          break
        case poolStatus === 3:
          statusText = 'Dead'
          break
        case poolStatus === 4:
          statusText = 'Alive'
          break
        case poolStatus === 5:
          statusText = 'Unknown'
          break
      }
      return statusText
    },
    currentStatus () {
      let statusClass = 'Unknown'
      switch (true) {
        case this.miner.os_version.search(/2020/) === 0 && this.miner.stats_timestamp > (this.timeNow - 60):
          statusClass = 'outdatedFirmware'
          this.itemState = statusClass
          break
        case this.miner.os_version.search(/2020/) === 0 && this.miner.stats_timestamp < (this.timeNow - 60):
          statusClass = 'Offline outdatedFirmware'
          this.itemState = statusClass
          break
        case this.status === 'error':
          statusClass = 'Error'
          this.itemState = statusClass
          break
        case this.status === 'rebooting':
          statusClass = 'Rebooting'
          this.itemState = statusClass
          break
        case this.status === '' && (this.miner.hashrate_ghs_5s !== 0 && this.miner.fanspeeds !== 0):
          statusClass = 'Online'
          this.itemState = statusClass
          break
        case (this.status === 'offline' || this.miner.stats_timestamp < (this.timeNow - 60)):
          statusClass = 'Offline'
          this.itemState = statusClass
          break
      }
      return statusClass
    },
    editWorkerName () {
      this.$store.dispatch(MINER__UPDATE, { id: this.miner.device_id, params: { name: this.workername.trim().split(' ').join(this.workernameDelimiter).split('.').join(this.workernameDelimiter) } })
    },
    deleteMiner () {
      this.$store.dispatch(MINER__DELETE, this.miner.device_id).then(
        this.$parent.reloadPage(this.miner.id)
      )
    },
    resetConfig () {
      this.$store.dispatch(MINER__DELETE, this.miner.device_id).then(
        this.$parent.reloadPage(this.miner.id)
      )
    },
    getMinerType () {
      const minerDetail = this.miner.model_id.split(' ')
      const minerType = minerDetail[0]

      return minerType
    },
    getMinerModel () {
      if (this.miner.model_id) {
        const minerDetail = this.miner.model_id.split(' ')
        const minerModel = minerDetail[1]
        return minerModel
      }
    },
    format1MinHashrate (data) {
      return ((data || 0) / 1000).toFixed(2)
    }
  }
}
</script>

<template>

  <div :class="'container-fluid dashboard--devices-page pro mobile_' + isMobile">

    <!-- CONTENT : HEADER -->

    <FarmgodHeader>
      <div class='row'>
        <div class='col-sm-12'>
          <Logo v-bind:link="'overview'" v-bind:title="'Powered by FarmGod.io'"/>
        </div>
      </div>
      <div class='row'>
        <section class='col alert alert-danger pulsate fI-slow' v-if='liveStatErrorMessage'>
          <b><translate>LIVESTAT-ERROR</translate> {{ liveStatErrorMessage.error_code }}</b><br/>{{ liveStatErrorMessage.error_detail }}
        </section>
        <section class='col alert alert-info pulsate fI-slow' v-if='!liveStatErrorMessage && latestStatMessage.miner_count_online === 0'>
          <b><translate>ATTENTION</translate></b><br/><translate>No miners online!</translate>
        </section>
        <NavBar v-bind:farm="farm" />
      </div>
    </FarmgodHeader>

    <!-- CONTENT : CORE -->

    <div class='row' style="margin-bottom:15px;">

      <!-- CONTENT : SIDEBAR LEFT -->

      <div v-if='!isMobile' class='col-2'>
        <SideBarLeft v-bind:farm="farm" v-bind:latestStatMessage="latestStatMessage" />
      </div>

      <!-- CONTENT : DEVICES -->

      <div :class="!isMobile ? 'col-8' : 'col-sm-12'">

        <div class="container_wrapper" id="_devices_">

           <!-- DEVICELIST HEAD : BOS -->

           <div v-if='product_mode == "bos"' class="container_header_detail" style="padding: 0px 15px 0px 15px!important; background-color: rgba(49, 49, 49, 0.85); color: rgb(228, 228, 228);">

              <div v-if='!isMobile' class="row deviceListHead fI">
                <div class="col-sm-1 deviceListXSCol" :title="$gettext('Current pool status of the device.')" style="margin-right: -10px;"><i class="fas fa-bezier-curve fas-small fas-left"></i></div>
                <div class="col-sm-2" :title="$gettext('The pool worker name of this asic.')" style="margin-right: -5px;"><i class="fas fa-hammer fas-small fas-left"></i><translate>WORKER</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('worker_name')" :title="'Click to sort by worker name.'" @click="setSorting('worker_name')"></i></div>
                <div class="col-sm-2" :title="$gettext('The ip-address of the asic.')" style="margin-left: -10px;"><i class="fas fa-network-wired fas-small fas-left"></i><translate>IP</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('ip_address')" :title="'Click to sort by IP-address.'" @click="setSorting('ip_address')"></i></div>
                <div class="col-sm-1" :title="$gettext('The miner type/model.')"><i class="fas fa-server fas-left fas-small"></i><translate>MINER</translate></div>
                <div class="col-sm-2" :title="$gettext('The current hashrate of this asic.')" style="margin-right: -10px;"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>RATE</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('hashrate_ghs_1m')" :title="$gettext('Click to sort by hashrate.')" @click="setSorting('hashrate_ghs_1m')"></i></div>
                <div class="col-sm-1" :title="$gettext('The amount of healthy asic chips across all boards.')"><i class="fab fa-buromobelexperte fas-small fas-left"></i><translate>ASC</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('active_asic_chips')" :title="$gettext('Click to sort by asic chip count.')" @click="setSorting('active_asic_chips')"></i></div>
                <div class="col-sm-1" :title="$gettext('The average temperature of the device.')"><i class="fas fa-thermometer-three-quarters fas-small fas-left"></i><translate>TMP</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('temperatures')" :title="$gettext('Click to sort by temperature.')" @click="setSorting('temperatures')"></i></div>
                <div class="col-sm-1" :title="$gettext('The average fanspeed of the device.')"><i class="fas fa-fan fas-small fas-left"></i><translate>FAN</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('fanspeeds')" :title="$gettext('Click to sort by fan speed.')" @click="setSorting('fanspeeds')"></i></div>
                <div class="col-sm-1" :title="$gettext('Extended.')" style="margin-left: 17px;"><i class="fas fa-cogs fas-small fas-left"></i><translate>MORE</translate></div>
              </div>
              <div v-else class="row deviceListHead fI">
                <div class="col-1" :title="$gettext('Type of miner.')" style="margin-left: 5px; margin-right: 0px;"><i class="fas fa-server fas-left fas-small"></i></div>
                <div class="col-4" :title="$gettext('Hashrate.')" style="margin-left: -5px; margin-right: -5px;"><i class="fas fa-chart-bar fas-small fas-left"></i></div>
                <div class="col-4" :title="$gettext('Workername')" style="margin-left: 0px; margin-right: 0px;"><i class="fas fa-hammer fas-small fas-left"></i></div>
                <div class="col-3" :title="$gettext('Extended.')"><i class="fas fa-cogs fas-small fas-left"></i></div>
              </div>

           </div>

           <!-- DEVICELIST HEAD : PRO -->

           <div v-else class="container_header_detail" style="padding: 0px 15px 0px 15px!important; background-color: rgba(49, 49, 49, 0.85); color: rgb(228, 228, 228);">

              <div v-if='!isMobile' class="row deviceListHead fI">
                <div class="col-sm-1 deviceListXSCol" :title="$gettext('Current pool status of the device.')" style="margin-right: -25px;margin-left: 0px;float: right;padding: 0;"><i class="fas fa-bezier-curve fas-small fas-left"></i></div>
                <!--div class="col-sm-2" :title="$gettext('The pool worker name of this asic.')" style="margin-right: -5px;"><i class="fas fa-hammer fas-small fas-left"></i><translate>WORKER</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('worker_name')" :title="'Click to sort by worker name.'" @click="setSorting('worker_name')"></i></div-->
                <div class="col-sm-2" :title="$gettext('The ip-address of the asic.')" style="margin-left: -5px; margin-right: 0px;"><i class="fas fa-network-wired fas-small fas-left"></i><translate>IP</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('ip_address')" :title="'Click to sort by IP-address.'" @click="setSorting('ip_address')"></i></div>
                <div class="col-sm-2" :title="$gettext('The miner type/model.')" style="margin-right: 10px;"><i class="fas fa-server fas-left fas-small"></i><translate>MINER</translate></div>
                <div class="col-sm-1" :title="$gettext('The current hashrate of this asic.')" style="margin-right: 3px;"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>RT</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('hashrate_ghs_1m')" :title="$gettext('Click to sort by hashrate.')" @click="setSorting('hashrate_ghs_1m')"></i></div>
                <div class="col-sm-1" :title="$gettext('The current pool hashrate of this asic.')" style="margin-right: 13px;"><i class="fas fa-chart-bar fas-small fas-left"></i><translate>POOL</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('pool_hashrate_1h')" :title="$gettext('Click to sort by hashrate.')" @click="setSorting('hashrate_ghs_1m')"></i></div>
                <div class="col-sm-1" :title="$gettext('The amount of healthy asic chips across all boards.')"><i class="fab fa-buromobelexperte fas-small fas-left"></i><translate>ASC</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('active_asic_chips')" :title="$gettext('Click to sort by asic chip count.')" @click="setSorting('active_asic_chips')"></i></div>
                <div class="col-sm-1" :title="$gettext('The average temperature of the device.')" style="margin-right: -5px;"><i class="fas fa-thermometer-three-quarters fas-small fas-left"></i><translate>TMP</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('temperatures')" :title="$gettext('Click to sort by temperature.')" @click="setSorting('temperatures')"></i></div>
                <div class="col-sm-1" :title="$gettext('The average fanspeed of the device.')" style="margin-right: -3px;"><i class="fas fa-fan fas-small fas-left"></i><translate>FAN</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('fanspeeds')" :title="$gettext('Click to sort by fan speed.')" @click="setSorting('fanspeeds')"></i></div>
                <div class="col-sm-1" :title="$gettext('The current approximate power consumption of the device.')"><i class="fas fa-bolt fas-small fas-left"></i><translate>PWR</translate><i :class="'fas fa-sort fas-small fas-right trans devlistSort ' + getSorting('power_consumption')" :title="$gettext('Click to sort by power consumption.')" @click="setSorting('power_consumption')"></i></div>
                <div class="col-sm-1" :title="$gettext('Extended.')" style="margin-left: 3px;"><i class="fas fa-cogs fas-small fas-left"></i><translate>MORE</translate></div>
              </div>
              <div v-else class="row deviceListHead fI">
                <div class="col-1" :title="$gettext('Type of miner.')" style="margin-left: 5px; margin-right: 0px;"><i class="fas fa-server fas-left fas-small"></i></div>
                <div class="col-4" :title="$gettext('Hashrate.')" style="margin-left: -5px; margin-right: -5px;"><i class="fas fa-chart-bar fas-small fas-left"></i></div>
                <div class="col-4" :title="$gettext('Workername')" style="margin-left: 0px; margin-right: 0px;"><i class="fas fa-hammer fas-small fas-left"></i></div>
                <div class="col-3" :title="$gettext('Extended.')"><i class="fas fa-cogs fas-small fas-left"></i></div>
              </div>

           </div>

           <!-- DEVICELIST BODY -->

           <div class="container_body_detail container_body_devices" style="padding: 0px 15px 0px 15px!important;" id="containerDevices">

            <!-- DEVICELIST : BOS -->

            <div class="form-group" id="deviceList" v-if='product_mode == "bos"' >
              <ItemRow v-for="(miner, index) in pagedMinersForFarm()"
                       :miner="miner"
                       :workerMode="farm ? farm.worker_mode : 0"
                       :workernameDelimiter="farm ? ((farm.worker_mode & 0x100000) === 0x100000 ? '_' : 'x') : 'n/a'"
                       :productMode="product_mode"
                       :key="index" >
              </ItemRow>
            </div>

            <!-- DEVICELIST : PRO -->

            <div class="form-group" id="deviceList" v-else >
              <ProItemRow v-for="(miner, index) in pagedMinersForFarm()"
                       :miner="miner"
                       :workerMode="farm ? farm.worker_mode : 0"
                       :workernameDelimiter="farm ? ((farm.worker_mode & 0x100000) === 0x100000 ? '_' : 'x') : 'n/a'"
                       :productMode="product_mode"
                       :key="index" >
              </ProItemRow>
            </div>

            <!-- PAGINATION : BOS -->

            <div class="mainFooter deviceListFooter fI" v-if='product_mode == "pro"'>

              <button v-if="this.pageNumber+1 > 1" id="deviceListLeftMost" type="button" class="btn btn-primary btn-main deviceBrowseBtn" @click="toFirstPage" title="Go to first page..."><i class="fas fa-angle-double-left"></i></button>
              <button v-else id="deviceListLeftMost" type="button" class="btn btn-secondary btn-main deviceBrowseBtn" style="cursor: not-allowed; opacity: 0.4;" title="Go to first page..."><i class="fas fa-angle-double-left"></i></button>

              <button v-if="this.pageNumber+1 > 1" id="deviceListLeft" type="button" class="btn btn-primary btn-main deviceBrowseBtn" @click="decrementPage" :title="$gettext('Go to previous page...')"><i class="fas fa-angle-left"></i></button>
              <button v-else id="deviceListLeft" type="button" class="btn btn-secondary btn-main deviceBrowseBtn" style="cursor: not-allowed; opacity: 0.4;" :title="$gettext('Go to previous page...')"><i class="fas fa-angle-left"></i></button>

              <div style="border-left: 1px solid rgb(64 64 64);display: inline;width: 1px;margin: 0 2px 0 2px;padding: 1px 0 6px 0;"></div>

              <button @click="setFilter('ALL')" type="button" title="Show 'ALL' asics." :class="'btn devicelistFilter' + ( filter === 'ALL' ? ' pulsate btn-success' : ' btn-secondary')"><i class="fas fa-server fas-left"></i><span>ALL <b>({{ latestStatMessage && latestStatMessage.miner_count_total ? latestStatMessage.miner_count_total : 'n/a' }})</b></span></button>
              <button @click="setFilter('ONLINE')" type="button" title="Show a list with 'ONLINE' asics." :class="'btn devicelistFilter' + ( filter === 'ONLINE' ? ' pulsate btn-success' : ' btn-secondary')"><i class="fas fa-check-circle fas-left"></i><span>ONLINE <b>({{ latestStatMessage && latestStatMessage.miner_count_online ? latestStatMessage.miner_count_online : 'n/a' }})</b></span></button>
              <button @click="setFilter('OFFLINE')" type="button" title="Show a list with 'OFFLINE' asics." :class="'btn devicelistFilter' + ( filter === 'OFFLINE' ? ' pulsate btn-success' : ' btn-secondary')"><i class="fas fa-times-circle fas-left"></i><span>OFFLINE <b>({{ latestStatMessage && latestStatMessage.miner_count_total && latestStatMessage.miner_count_online  ? latestStatMessage.miner_count_total - latestStatMessage.miner_count_online : 'n/a' }})</b></span></button>
              <button @click="setFilter('FAULTY')" type="button" title="Show a list with 'FAULTY' asics." :class="'btn devicelistFilter' + ( filter === 'FAULTY' ? ' pulsate btn-success' : ' btn-secondary')"><i class="fas fa-exclamation-triangle fas-left"></i><span>FAULTY <b>({{ latestStatMessage && latestStatMessage.miner_count_total ? latestStatMessage.miner_count_faulty : 'n/a' }})</b></span></button>

              <div style="border-left: 1px solid rgb(64 64 64);display: inline;width: 1px;margin: 0 2px 0 2px;padding: 1px 0 6px 0;"></div>

              <button v-if="this.pageNumber+1 < Math.ceil(this.currentMinerObjCount / this.limit)" id="deviceListRight" type="button" class="btn btn-primary btn-main deviceBrowseBtn" @click="incrementPage" :title="$gettext('Go to next page...')"><i class="fas fa-angle-right"></i></button>
              <button v-else id="deviceListRight" type="button" class="btn btn-secondary btn-main deviceBrowseBtn" style="cursor: not-allowed; opacity: 0.4;"><i class="fas fa-angle-right" :title="$gettext('Go to next page...')"></i></button>

              <button v-if="this.pageNumber+1 < Math.ceil(this.currentMinerObjCount / this.limit)" id="deviceListRightMost" type="button" class="btn btn-primary btn-main deviceBrowseBtn" @click="toLastPage(this.currentMinerObjCount)" title="Go to last page..."><i class="fas fa-angle-double-right"></i></button>
              <button v-else id="deviceListRightMost" type="button" class="btn btn-secondary btn-main deviceBrowseBtn" style="cursor: not-allowed; opacity: 0.4;"><i class="fas fa-angle-double-right" title="Go to last page..."></i></button>

              <div v-if='!isMobile' class="paginationDetailFooter">
                <small style="letter-spacing: .5px;color: rgb(197 197 197);"><translate>BROWSING PAGE</translate> {{ this.pageNumber+1 }}/{{ Math.ceil((this.currentMinerObjCount !== null ? this.currentMinerObjCount : this.latestStatMessage.miner_count_total) / this.limit) }} &#124; </small>
                <small>
                  <FormulateInput
                    type='select'
                    v-model='limit'
                    v-on:change='inputWatcher'
                    :options='{ 25: 25, 50: 50, 100: 100, 250: 250 }'
                    class='devicelistDropdown pulsate'
                    /> PER PAGE
                </small>
                <span style="color:rgb(130 130 130); margin:0 2px 0 2px;"> &#124; </span>
                <button type="button" style="cursor:not-allowed;" class="btn devicelistDownloadBtn xsBtn xsBtnSmall" :title="$gettext('Download the current category of miners as csv file.')"><i class="fas fas-xsBtn fa-download" style="margin-right:2px;"></i> DOWNLOAD '{{ filter }}' AS CSV</button>
              </div>
              <div v-else class="paginationDetailFooter"><small style="letter-spacing:1px; color:#fff;"><translate>PAGE</translate> {{ this.pageNumber+1 }}/{{ Math.ceil(this.currentMinerObjCount / this.limit) }}</small></div>

            </div>

            <!-- PAGINATION : PRO -->

            <div class="mainFooter deviceListFooter fI" v-else>

              <button v-if="this.pageNumber+1 > 1" id="deviceListLeft" type="button" class="btn btn-primary btn-main deviceBrowseBtn" @click="decrementPage" :title="$gettext('Go to previous page...')"><i class="fas fa-angle-left"></i></button>
              <button v-else id="deviceListLeft" type="button" class="btn btn-secondary btn-main deviceBrowseBtn" style="cursor: not-allowed; opacity: 0.4;" :title="$gettext('Go to previous page...')"><i class="fas fa-angle-left"></i></button>

              <div v-if='!isMobile' class="paginationDetail"><i class="fas fa-server fas-left fas-small"></i><b><span class="color03">'{{ ( latestStatMessage ? latestStatMessage.miner_count_total : 0 ) }}'</span> <translate>ASIC(S) FOUND</translate></b> <small style="letter-spacing:1px; color:#fff;"> &#124; <translate>SHOWING PAGE</translate> {{ this.pageNumber+1 }}/{{ Math.ceil(latestStatMessage.miner_count_total / this.limit) }}</small></div>
              <div v-else class="paginationDetail"><small style="letter-spacing:1px; color:#fff;"><translate>PAGE</translate> {{ this.pageNumber+1 }}/{{ Math.ceil(latestStatMessage.miner_count_total / this.limit) }}</small></div>

              <button v-if="this.pageNumber+1 < Math.ceil(latestStatMessage.miner_count_total / this.limit)" id="deviceListRight" type="button" class="btn btn-primary btn-main deviceBrowseBtn" @click="incrementPage" :title="$gettext('Go to next page...')"><i class="fas fa-angle-right"></i></button>
              <button v-else id="deviceListRight" type="button" class="btn btn-secondary btn-main deviceBrowseBtn" style="cursor: not-allowed; opacity: 0.4;"><i class="fas fa-angle-right" :title="$gettext('Go to next page...')"></i></button>

            </div>

          </div>

        </div>

      </div>

      <!-- CONTENT : SIDEBAR RIGHT -->

      <div v-if='!isMobile' class='col-2'>
        <SideBarRight v-bind:latestStatMessage="latestStatMessage" v-bind:farm="farm" />
      </div>

    </div>

    <!-- CONTENT : FOOTER -->

    <FarmgodFooter>
      <LoggedInFooter />
    </FarmgodFooter>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'

import { FARM__READ, FARM__READ_MINERS, LIVE_STAT__CONNECT, LIVE_STAT__DISCONNECT, DEVICELIST__LIVE_STATS__TOGGLE } from '@/store/actions.type'
import Farm from '@/models/farm'
import Miner from '@/models/miner'
import { PRODUCT_MODE } from '@/common/config'

import ItemRow from '@/components/views/Detail/Devices/ItemRow.vue'
import ProItemRow from '@/components/views/Detail/Devices/ProItemRow.vue'

import FarmgodHeader from '@/components/layout/TheHeader'
import NavBar from '@/components/views/Detail/NavBar'
import Logo from '@/components/layout/Logo'
import FarmgodFooter from '@/components/layout/TheFooter'
import LoggedInFooter from '@/components/layout/Footers/LoggedIn'
import SideBarLeft from '@/components/layout/SidebarLeft'
import SideBarRight from '@/components/layout/SidebarRight'

export default {
  name: 'devices',
  components: { FarmgodHeader, FarmgodFooter, LoggedInFooter, SideBarLeft, SideBarRight, Logo, NavBar, ItemRow, ProItemRow },
  data () {
    return {
      product_mode: PRODUCT_MODE,
      shouldReceiveLive: true,
      pageNumber: 0,
      filter: 'ALL',
      currentMinerObjCount: this.latestStatMessage ? this.latestStatMessage.miner_count_total : null,
      limit: PRODUCT_MODE === 'pro' ? 100 : 100,
      orderBy: 'worker_name',
      sortOrder: 'asc',
      state: 'null',
      error_state: 'null'
    }
  },
  methods: {
    pagedMinersForFarm () {
      const offset = this.limit * this.pageNumber
      return Miner.allForFarm(this.$route.params.farm_id, this.orderBy, this.sortOrder).limit(this.limit).offset(offset).get()
    },
    readMiners (offset) {
      this.$store.dispatch(FARM__READ_MINERS, { farmId: this.$route.params.farm_id, skip: offset, limit: parseInt(this.limit) + parseInt(this.limit), orderBy: this.orderBy, sortOrder: this.sortOrder, state: this.state, error_state: this.error_state }).then(
        this.restartPolling(offset)
      )
    },
    restartPolling (offset) {
      this.$store.dispatch(DEVICELIST__LIVE_STATS__TOGGLE, false).then(
        this.$store.dispatch(DEVICELIST__LIVE_STATS__TOGGLE, { status: true, id: this.$route.params.farm_id, offset: offset, limit: parseInt(this.limit) + parseInt(this.limit), orderBy: this.orderBy, sortOrder: this.sortOrder, state: this.state, error_state: this.error_state })
      )
    },
    incrementPage () {
      this.pageNumber++
      const offset = this.limit * this.pageNumber
      this.readMiners(offset)
    },
    decrementPage () {
      this.pageNumber--
      const offset = this.limit * this.pageNumber
      this.readMiners(offset)
    },
    toFirstPage () {
      this.pageNumber = 0
      this.readMiners(0)
    },
    toLastPage (minerCount) {
      this.pageNumber = Math.ceil(minerCount / this.limit) - 1
      const offset = this.limit * this.pageNumber
      this.readMiners(offset)
    },
    reloadPage (id) {
      Miner.delete(id)
    },
    getSorting (column) {
      let result = null
      switch (true) {
        case (this.orderBy === column):
          result = 'devlistSortActive'
          break
      }
      return result
    },
    setSorting (column) {
      if (this.orderBy === column) {
        if (this.sortOrder === 'asc') {
          this.sortOrder = 'desc'
        } else {
          this.sortOrder = 'asc'
        }
      } else {
        if (this.sortOrder === 'asc') {
          this.sortOrder = 'desc'
        } else {
          this.sortOrder = 'asc'
        }
        this.orderBy = column
      }
      const offset = this.limit * this.pageNumber
      this.readMiners(offset)
    },
    setFilter (f) {
      if (f === 'ONLINE') {
        this.filter = 'ONLINE'
        this.state = 1
        this.error_state = 'null'
        this.currentMinerObjCount = this.latestStatMessage.miner_count_online
      } else if (f === 'OFFLINE') {
        this.filter = 'OFFLINE'
        this.state = 0
        this.error_state = 'null'
        this.currentMinerObjCount = this.latestStatMessage.miner_count_total - this.latestStatMessage.miner_count_online
      } else if (f === 'FAULTY') {
        this.filter = 'FAULTY'
        this.state = 'null'
        this.error_state = 1
        this.currentMinerObjCount = this.latestStatMessage.miner_count_faulty
      } else {
        this.filter = 'ALL'
        this.state = 'null'
        this.error_state = 'null'
        this.currentMinerObjCount = this.latestStatMessage.miner_count_total
      }
      this.pageNumber = 0
      Miner.deleteAll()
      this.readMiners(0)
    },
    inputWatcher () {
      this.setFilter(this.filter)
    }
  },
  beforeCreate () {
    Miner.deleteAll()
  },
  created () {
    if (this.$route.query.filter) {
      this.setFilter(this.$route.query.filter)
    }
    const offset = this.limit * this.pageNumber
    this.readMiners(offset)
    if (this.limit < 100) {
      console.log('Live Device-Updates enabled!')
      this.$store.dispatch(`livestats/${LIVE_STAT__DISCONNECT}`, this.$route.params.farm_id).then(
        this.$store.dispatch(`livestats/${LIVE_STAT__CONNECT}`, { farmId: this.$route.params.farm_id })
      )
    } else {
      console.log('Live Device-Updates disabled (Items per page > 50).')
    }
  },
  beforeDestroy () {
    this.$store.dispatch(DEVICELIST__LIVE_STATS__TOGGLE, false)
  },
  computed: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    ...mapGetters({ latestStatMessage: 'livestats/latestStatMessage', liveStatErrorMessage: 'livestats/liveStatErrorMessage' }, 'receiveDeviceLiveStats'),
    farm () {
      const farm = Farm.find(this.$route.params.farm_id)
      if (farm) {
        return farm
      } else {
        this.$store.dispatch(FARM__READ, this.$route.params.farm_id)
        return null
      }
    }
  }
}
</script>

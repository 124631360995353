<template>

  <span :class="'fI-slow mobile_' + isMobile()">

    <!--button v-if='!isMobile()' type="button" style="width: 50px!important;" :class="'btn btn-' + statsBtn + ' xsBtn singleBtn'" :title="$gettext('View details about this miner')" @click="$refs.statsModal.openModal()"><i class="fas fas-xsBtn fa-chart-bar"></i> {{ statsBtnText }}</button-->
    <button v-if='!isMobile()' type="button" :class="'btn btn-' + (status === 'Offline' ? 'secondary' : 'success') + ' xsBtn xsBtnSquareSmall'" :title="$gettext('View details about this miner')" @click="$refs.statsModal.openModal()"><i class="fas fas-xsBtn fa-chart-bar"></i> {{ statsBtnText }}</button>
    <button v-else type="button" class="btn btn-primary xsBtn xsBtnSquareSmall" style="width: 30px!important; margin-right: 5px;" :title="$gettext('View details about this miner')" @click="$refs.statsModal.openModal()"><i class="fas fas-xsBtn fa-chart-bar"></i></button>

    <!-- MODAL : DEVICE STATS -->

    <Modal ref="statsModal" class="statsModal">

      <template v-slot:header>
        <span v-if="!isMobile()">
          <h5 class="modal-title" style="text-align: left; margin-left: 15px; font-size: 1.7em;"><i class="fas fa-chart-bar fas-left"></i><b>{{ miner.model_id }}</b><small style="font-size:70%;"> @ {{ miner.ip_address }} _</small> <small class="color01" style="font-size:70%;"> {{ miner.mac_address }}</small><br />
            <span class="small" style="font-size:0.6em;"><b class="color10"><translate>WORKER</translate>:</b> {{ miner.worker_name ? miner.worker_name : 'n/a' }}<span class="color08"> \\ </span><b class="color10"><translate>DEVICE-ID</translate>:</b> <span style="text-transform:none;">{{ miner.device_id }}</span> <i class="fas fa-copy fas-left grey" style="cursor: pointer!important;margin-left:3px;" @click="copyDeviceId();$refs.copyDeviceIdModal.openModal();" :title="$gettext('Copy the Device-ID to the clipboard.')"></i></span><br />
          </h5>
        </span>
        <span v-else>
          <h5 class="modal-title" style="text-align: left; margin-left: 15px; font-size: 1.35em;"><i class="fas fa-chart-bar fas-left"></i><b>{{ miner.model_id }}</b> <small class="color01"> @ {{ miner.ip_address }}</small><br />
            <span class="small" style="font-size:0.5em;"><b class="color10"><translate>WORKER</translate>:</b> {{ miner.worker_name ? miner.worker_name : 'n/a' }}<span class="color08" style="text-transform:none;"> \\ </span><b class="color10"><translate>DEVICE-ID</translate>:</b> <span style="text-transform:none;">{{ miner.device_id }}</span> <i class="fas fa-copy fas-left grey" style="cursor: pointer!important;" @click="copyDeviceId();$refs.copyDeviceIdModal.openModal();$refs.copyDeviceIdModal.openModal();" :title="$gettext('Copy the Device-ID to the clipboard.')"></i></span><br />
          </h5>
        </span>

      </template>

      <template v-slot:body>
        <div class="container-fluid">
          <div class="row">
            <div class='col-sm-6'>
              <div class="row">
                <div class='col-6' style="padding-left: 15px;padding-right: 7px;">
                  <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                    <label class="modal-stats-header" :style="'width: 100%!important;text-align:center;color: #fff; background: ' + lastHeartbeat() + ';'"><i class="fas fas-xsBtn fas-left fa-clock"></i><b><translate>HEARTBEAT</translate></b></label>
                    <input v-if="timeOut" disabled class="form-control" type="text" :value="(new Date(miner.stats_timestamp * 1000)).toLocaleTimeString() + ' (' + (new Date(miner.stats_timestamp * 1000)).toLocaleDateString() + ')'" style="height: 31px; text-align: center; width: 100% !important; color: rgb(169, 169, 169) !important;font-weight:bold;"/>
                    <input v-else disabled class="form-control" type="text" :value="timeFormatHeartbeat(miner.stats_timestamp)" style="height: 31px; text-align: center; width: 100% !important; color: rgb(169, 169, 169) !important;font-weight:bold;"/>
                  </div>
                </div>
                <div class='col-6' style="padding-left: 7px;padding-right: 15px;">
                  <div v-if="timeOut" class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                    <label class="modal-stats-header" :style="'text-align:center;width: 100%!important;color: #fff; background: #520000;'"><i class="fas fas-xsBtn fas-left fa-bezier-curve"></i><b><translate>POOL STATUS</translate></b></label>
                    <input disabled class="form-control" type="text" :value="'n/a'" style="width: 100%!important;height:31px;text-align:center;font-weight:bold; color: rgb(169, 169, 169) !important;"/>
                  </div>
                  <div v-else class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                    <label class="modal-stats-header" :style="'text-align:center;width: 100%!important;color: #fff; background: ' + getPoolstatusColor(miner.pool_status) + ';'"><i class="fas fas-xsBtn fas-left fa-bezier-curve"></i><b><translate>POOL STATUS</translate></b></label>
                    <input disabled class="form-control" type="text" :value="getPoolstatus(miner.pool_status)" style="width: 100%!important;height:31px;text-align:center;font-weight:bold; color: rgb(169, 169, 169) !important;"/>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-sm-6'>
              <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-file-archive"></i><b><translate>FIRMWARE</translate></b></label>
                <input disabled class="form-control" type="text" :value="miner.model_id + ' / ' + miner.platform_name + ' (' + miner.os_version + ')'" style="height:31px;width:100%!important; text-align: center;font-weight: bold;color: #6b6b6b!important;"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-sm-6'>
              <div class="row">
                <div class='col-6' style="padding-left: 15px;padding-right: 7px;">
                  <div class='form-group'>
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-chart-line"></i><b><translate>HASHRATE RT</translate> (1M)</b></label>
                    <input disabled class="form-control" type="text" :value="formatHashrate(miner.hashrate_ghs_1m)" style="height:31px;text-align:center;width: 100%!important;color:#71751d!important;"/>
                  </div>
                  <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-chart-line"></i><b><translate>HASHRATE RT</translate> (5M)</b></label>
                    <input disabled class="form-control" type="text" :value="formatHashrate(miner.hashrate_ghs_5m)" style="height:31px;text-align:center;width: 100%!important;font-weight:bold;color:#71751d!important;font-size:1.1em;"/>
                  </div>
                  <div class="row">
                    <div class='col-sm-6' style="padding-left: 15px;padding-right: 5px;">
                      <div class='form-group'>
                        <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-check-circle"></i><b><translate>SHARES ACCEPTED</translate></b></label>
                        <input disabled class="form-control" type="text" :value="miner.shares_accepted ? miner.shares_accepted : 'n/a'" style="height:31px;text-align:center;width: 100%!important;color: #a9a9a9!important;"/>
                      </div>
                      <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                        <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-clock"></i><b><translate>SHARES STALE</translate></b></label>
                        <input disabled class="form-control" type="text" :value="miner.shares_stale ? miner.shares_stale : 'n/a'" style="height:31px;text-align:center;width: 100%!important;color: #a9a9a9!important;"/>
                      </div>
                    </div>
                    <div class='col-sm-6' style="padding-right: 15px;">
                      <div class='form-group'>
                        <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-times-circle"></i><b><translate>SHARES REJECTED</translate></b></label>
                        <input disabled class="form-control" type="text" :value="miner.shares_rejected ? miner.shares_rejected : 'n/a'" style="height:31px;text-align:center;width: 100%!important;color: #a9a9a9!important;"/>
                      </div>
                      <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                        <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-exclamation-triangle"></i><b><translate>SHARES INVALID</translate></b></label>
                        <input disabled class="form-control" type="text" :value="miner.shares_invalid ? miner.shares_invalid : 'n/a'" style="height:31px;text-align:center;width: 100%!important;color: #a9a9a9!important;"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-6' style="padding-left: 5px;padding-right: 15px;">
                  <div class='form-group'>
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-chart-line"></i><b><translate>HASHRATE POOL</translate> (5M)</b></label>
                    <input disabled class="form-control" type="text" :value="formatHashrate(miner.pool_hashrate_1m)" style="height:31px;text-align:center;width: 100%!important;color:#acacac!important;"/>
                  </div>
                  <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-chart-line"></i><b><translate>HASHRATE POOL</translate> (12H)</b></label>
                    <input disabled class="form-control" type="text" :value="formatHashrate(miner.pool_hashrate_5m)" style="height:31px;text-align:center;width: 100%!important;font-weight:bold;color:#acacac!important;font-size:1.1em;"/>
                  </div>
                  <div class="row">
                    <div class='col-sm-6' style="padding-left: 15px;padding-right: 5px;">
                      <div class='form-group'>
                        <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-check-circle"></i><b><translate>SHARES ACCEPTED</translate></b></label>
                        <input disabled class="form-control" type="text" :value="miner.pool_shares_accepted ? miner.pool_shares_accepted : 'n/a'" style="height:31px;text-align:center;width: 100%!important;color: #a9a9a9!important;"/>
                      </div>
                      <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                        <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-clock"></i><b><translate>SHARES STALE</translate></b></label>
                        <input disabled class="form-control" type="text" :value="miner.pool_shares_stale ? miner.pool_shares_stale : 'n/a'" style="height:31px;text-align:center;width: 100%!important;color: #a9a9a9!important;"/>
                      </div>
                    </div>
                    <div class='col-sm-6' style="padding-right: 15px;">
                      <div class='form-group'>
                        <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-times-circle"></i><b><translate>SHARES REJECTED</translate></b></label>
                        <input disabled class="form-control" type="text" :value="miner.pool_shares_rejected ? miner.pool_shares_rejected : 'n/a'" style="height:31px;text-align:center;width: 100%!important;color: #a9a9a9!important;"/>
                      </div>
                      <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                        <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-exclamation-triangle"></i><b><translate>SHARES INVALID</translate></b></label>
                        <input disabled class="form-control" type="text" :value="miner.pool_shares_invalid ? miner.pool_shares_invalid : 'n/a'" style="height:31px;text-align:center;width: 100%!important;color: #a9a9a9!important;"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-sm-12'>
                  <div class='form-group'>
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-file"></i><b><translate>NOTE</translate></b></label>
                    <input class="form-control" type="text" v-model="theNote" placeholder="" style="height: 31px;text-align: center;font-weight: bold;width: 95%!important;float: left;color: rgb(169, 169, 169) !important;display: inline;background-color: #0e0e0e!important;border: 1px solid rgb(86 86 86)!important;"/>
                    <button style="float: right;height: 32px;width: 26px;margin-top: -1px;margin-right: 0px;" class='btn btn-primary' @click="addNote(theNote); $parent.$refs.addNoteModal.openModal();"><i class="fas fas-xsBtn fa-save"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class='col-sm-6'>
              <div class="row">
                <div class='col-6' style="padding-left: 15px;padding-right: 7px;">
                  <div class='form-group'>
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-tachometer-alt"></i><b><translate>TUNER STATUS</translate></b></label>
                    <input disabled class="form-control" type="text" :value="miner.autotuning_running ? (miner.autotuning_running === 1 ? 'running' : 'not running') : 'n/a'" style="height: 31px; text-align: center; width: 100% !important; color: rgb(169, 169, 169) !important;"/>
                  </div>
                  <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-bolt"></i><b><translate>POWERDRAW</translate></b></label>
                    <input disabled class="form-control" type="text" :value="miner.power_consumption + ' W'" :style="'height: 31px; text-align: center; width: 100% !important; color: rgb(169, 169, 169) !important;'"/>
                  </div>
                  <div class='form-group'>
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-grip-lines-vertical"></i><b><translate>BOARDS</translate></b></label>
                    <input disabled class="form-control" type="text" :value="miner.active_boards" :style="'height:31px;text-align:center;width: 100%!important; color:' + chipcountIndication + '!important;'"/>
                  </div>
                  <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fab fas-xsBtn fas-left fa-buromobelexperte"></i><b><translate>ASICS</translate></b></label>
                    <input disabled class="form-control" type="text" :value="formatArray(miner.active_asic_chips_array)" :style="'height:31px;text-align:center;width: 100%!important; color:' + chipcountIndication + '!important;'"/>
                  </div>
                  <div class='form-group' style="padding-bottom: 15px;">
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-bug"></i><b><translate>HW ERRORS</translate></b></label>
                    <input disabled class="form-control" type="text" :value="miner.hw_errors ? miner.hw_errors : 'n/a'" :style="'height:31px;text-align:center;width: 100%!important;'"/>
                  </div>
                </div>
                <div class='col-6' style="padding-left: 7px;padding-right: 15px;">
                  <div class='form-group'>
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-clock"></i><b><translate>UPTIME</translate></b></label>
                    <input v-if="timeOut" disabled class="form-control" type="text" value="n/a" style="height: 31px; text-align: center; width: 100% !important; color: rgb(169, 169, 169) !important;"/>
                    <input v-else disabled class="form-control" type="text" :value="timeFormatUptime(miner.total_secs_measurement)" style="height: 31px; text-align: center; width: 100% !important; color: rgb(169, 169, 169) !important;"/>
                  </div>
                  <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fab fas-xsBtn fas-left fa-buromobelexperte"></i><b><translate>EFFICIENCY</translate></b></label>
                    <input disabled class="form-control" type="text" :value="getEfficiency() + ' J/TH'" :style="'height: 31px; text-align: center; width: 100% !important; color: rgb(169, 169, 169) !important;'"/>
                  </div>
                  <div class='form-group'>
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-fan"></i><b><translate>FANS</translate></b></label>
                    <input disabled class="form-control" type="text" :value="miner.active_fans" :style="'height: 31px; text-align: center; width: 100% !important; color: rgb(169, 169, 169) !important;'"/>
                  </div>
                  <div class='form-group' style="padding-bottom: 15px; border-bottom: 1px dashed rgb(86 86 86);">
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-fan"></i><b><translate>FAN SPEED</translate></b></label>
                    <input disabled class="form-control" type="text" :value="formatArray(miner.fanspeeds_array)" :style="'height:31px;text-align:center;width: 100%!important; color:' + fanspeedIndication + '!important;'"/>
                  </div>
                  <div class='form-group'>
                    <label class="modal-stats-header" style="width:100%!important; text-align: center;"><i class="fas fas-xsBtn fas-left fa-thermometer-half"></i><b><translate>TEMPERATURES</translate></b></label>
                    <input disabled class="form-control" type="text" :value="formatTemp(miner.temperatures_array)" :style="'height:31px;text-align:center;width: 100%!important; color:' + temperatureIndication + '!important;'"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>

      <template v-slot:footer>

        <div style="display:inline-flex;width:80%; margin-top: -15px;">
          <button style="color: #fff; margin-right:10px;" class='btn btn-danger' @click="$refs.statsModal.closeModal();$parent.$refs.deleteMinerModal.openModal();"><i class="fas fas-xsBtn fa-trash fas-left"></i> <translate>DELETE MINER</translate></button>
          <button style="color: #fff; margin-left:10px; cursor:not-allowed;" class='btn btn-success' @click="$refs.statsModal.closeModal();"><i class="fas fas-xsBtn fa-upload fas-left"></i> <img src="@/assets/img_core/braiins-os-plus.png" class="coinTickerImg" width="75" title="Braiins OS+"> \\ <small><b>Compatible</b> -<translate> Click to install!</translate></small></button>
        </div>

      </template>

    </Modal>

    <!-- MODAL : COPY DEVICE-ID -->

    <Modal ref="copyDeviceIdModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-fingerprint fas-left"></i> <translate>DEVICE-ID</translate></h5>
      </template>
      <template v-slot:body>
        <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
          <div><i class="grow fas fa-check fas-small fas-left green" style="font-size: 4em;"></i></div>
          <div class="pulsate green" style="letter-spacing: 1px; font-size: 1em; margin-top: 0px; font-weight: bold;"> {{ miner.device_id }}</div>
          <div class="pulsate" style="margin-top:12px;" v-translate>The <b>Device-ID</b> has been copied to the clipboard!</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary' @click="$refs.copyDeviceIdModal.closeModal();"><i class="fas fas-xsBtn fa-times fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

  </span>

</template>

<script>
import Modal from '@/components/widgets/Modal.vue'
import { PRODUCT_MODE } from '@/common/config'
import { MINER__ADD_NOTE } from '@/store/actions.type'
import moment from 'moment'
// import 'moment-duration-format'

export default {
  name: 'devices-stats-modal',
  components: { Modal },
  props: ['miner', 'fanspeedIndication', 'temperatureIndication', 'chipcountIndication', 'statsBtn', 'statsBtnText', 'status'],
  data () {
    return {
      theNote: this.miner.note,
      product_mode: PRODUCT_MODE,
      timeNow: Math.floor(Date.now() / 1000),
      timeOut: false
    }
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    addNote (note) {
      this.$store.dispatch(MINER__ADD_NOTE, { id: this.miner.device_id, params: { name: this.miner.name, note: note, state: this.miner.state } })
    },
    async copyDeviceId () {
      await navigator.clipboard.writeText(this.miner.device_id)
    },
    getEfficiency () {
      if (this.miner.hashrate_ghs_1m > 0) {
        return (this.miner.power_consumption / (this.miner.hashrate_ghs_1m / 1000)).toFixed(0)
      } else {
        return 'n/a'
      }
    },
    lastHeartbeat () {
      let statusClass = 'red'
      switch (true) {
        case this.miner.stats_timestamp > (this.timeNow - 180):
          this.timeOut = false
          statusClass = '#083a1a'
          break
        case this.miner.stats_timestamp < (this.timeNow - 180):
          this.timeOut = true
          statusClass = '#520000'
          this.itemState = statusClass
          break
      }
      return statusClass
    },
    formatHashrate (data) {
      if (data) {
        return ((data || 0) / 1000).toFixed(2) + ' TH/s'
      } else {
        return 'n/a'
      }
    },
    formatTemp (array) {
      if (array) {
        array = array.map(a => a.toFixed(0) + '°C')
        return array.toString().split(',').join(' | ')
      } else {
        return 'n/a'
      }
    },
    formatArray (array) {
      if (array) {
        array = array.map(a => a.toFixed(0))
        return array.toString().split(',').join(' | ')
      } else {
        return 'n/a'
      }
    },
    getPoolstatus (data) {
      const poolStatus = this.miner.pool_status || 0
      let statusText = null
      switch (true) {
        case poolStatus === 0:
          statusText = 'UNSPECIFIED'
          break
        case poolStatus === 1:
          statusText = 'DISABLED'
          break
        case poolStatus === 2:
          statusText = 'REJECTING'
          break
        case poolStatus === 3:
          statusText = 'DEAD'
          break
        case poolStatus === 4:
          statusText = 'ALIVE'
          break
        case poolStatus === 5:
          statusText = 'UNKNOWN'
          break
      }
      return statusText
    },
    getPoolstatusColor (data) {
      const poolStatus = this.miner.pool_status || 0
      let statusColor = null
      switch (true) {
        case poolStatus === 0:
          statusColor = ' rgb(142 110 9)'
          break
        case poolStatus === 1:
          statusColor = ' rgb(142 110 9)'
          break
        case poolStatus === 2:
          statusColor = ' #520000'
          break
        case poolStatus === 3:
          statusColor = ' #520000'
          break
        case poolStatus === 4:
          statusColor = ' #083a1a'
          break
        case poolStatus === 5:
          statusColor = ' #520000'
          break
      }
      return statusColor
    },
    timeFormatHeartbeat (t) {
      const diff = Math.floor(Date.now() / 1000) - t
      let formatted = 0
      if (diff < 2) {
        formatted = moment(diff * 1000).format('s [second ago...]')
      } else {
        formatted = moment(diff * 1000).format('s [seconds ago...]')
      }
      return formatted
    },
    timeFormatUptime (t) {
      var formatted = null
      var days = Math.round(t / 86400)
      if (t > 86399) {
        formatted = days + ' day(s) ' + moment(parseInt(t) * 1000).utc().format('H [hour(s)] mm [minute(s)]')
      } else {
        formatted = moment(parseInt(t) * 1000).utc().format('H [hour(s)] mm [minute(s)]')
      }
      return formatted
    }
  },
  computed: {
  }
}
</script>
